import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import BenefitsTab from './Tabs/BenefitsTab';
import ClassroomTab from './Tabs/ClassroomTab';

const EducationTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box width="100%" p={2} my={7} border={1} bgcolor="#fff" borderColor="#eee">
      <Box width="100%" borderBottom={1} borderColor="#e8e8e8">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="How does it work in my classroom?" />
          <Tab label="What do I get?" />
        </Tabs>
      </Box>

      <ClassroomTab value={value} index={0} />
      <BenefitsTab value={value} index={1} />
    </Box>
  );
};

export default EducationTabs;

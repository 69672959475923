import React from 'react';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';

import Container from '~components/Container';
import {
  EducationHero,
  EducationTabs,
  EducationMaterials,
  EducationTestimonials,
  EducationPartners,
  EducationPricing,
  EducationContacts
} from '~components/education';

import TabsSectionBg from '~images/education/tabs-section-background.jpg';
import HeroBg from '~images/education/hero-bg.jpg';

const seo = {
  seo_title: 'Torah Lessons For Teachers & Educators',
  seo_description:
    'Aleph Beta’s videos and worksheets are for every type of teacher, leader, and learner who seeks to strengthen a love for Torah, designed to fit seamlessly into any curriculum.',
  seo_keywords: 'torah lessons, torah teachers, torah teachings'
};

const Education = () => (
  <Page>
    <SEO {...seo} />
    <Container maxWidth="xl">
      <Container size={50} center background={`url(${HeroBg})`}>
        <EducationHero />
      </Container>

      <Container center background={`url(${TabsSectionBg})`}>
        <EducationTabs />
      </Container>

      <Container center background="#f6f9fa">
        <EducationMaterials />
      </Container>

      <Container size={50} center>
        <EducationPartners />
      </Container>

      <Container size={66} center background="#f6f9fa">
        <EducationTestimonials />
      </Container>

      <Container size={33} center>
        <EducationPricing />
      </Container>

      <Container center>
        <EducationContacts />
      </Container>
    </Container>
  </Page>
);

export default Education;

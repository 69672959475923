import React from 'react';
import { navigate } from 'gatsby';

import Fab from '@material-ui/core/Fab';

import useCheckout from '~hooks/useCheckout';
import products, { PRODUCT_IDS } from '~utils/products';
import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const EducationSigUpButton = ({ ...rest }) => {
  const [, setCheckoutState] = useCheckout();
  const { isMarketingSite } = useConversionFramework();

  const handleSignUpClick = () => {
    const product = products.find(({ id }) => id === PRODUCT_IDS.Education);
    if (isMarketingSite) {
      redirectToMembersCheckout(product, 'year');
    } else {
      setCheckoutState({ product, billingCycle: 'year' });
      navigate('/checkout');
    }
  };
  return (
    <Fab variant="extended" onClick={handleSignUpClick} {...rest}>
      Sign Up Now!
    </Fab>
  );
};

export default EducationSigUpButton;

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import GrowingImage from '~images/education/growing.png';

const useStyles = makeStyles(theme => ({
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto',
    padding: `${theme.spacing(3)}px  0`
  },
  textColor: {
    color: '#fff'
  }
}));

const LEARNING_METHODS = [
  {
    title: 'In-Class Learning',
    text: 'You may choose to use a video in one entire session. You can prepare the class with some introductory material before the video is shown and then pause the video at critical points within the video to foster discussion and active learning. At the end of the video, you can unpack the content and help students apply it to their own lives. Don’t worry, you’re not alone in this endeavour -- our teacher guides and lesson plans help you do just that!',
    backgroundColor: '#be6eba'
  },
  {
    title: 'Homework',
    text: 'You may choose to assign the videos for homework and unpack the lesson in class the next day. This gives you the ability to work on the skills and tools brought up in the video. It will also help you enable your students to gain unpack the rich spiritual meaning within each video.',
    backgroundColor: '#9b93c3'
  },
  {
    title: 'Blended Learning',
    text: 'Split your class into groups and prompt them with questions to unpack the video together. After the groups are done, your class can have a lively discussion comparing and contrasting their thoughts and ideas.',
    backgroundColor: '#78b7cd'
  }
];

const ClassroomTab = ({ value, index }) => {
  const classes = useStyles();
  return (
    <Box hidden={value !== index}>
      <img src={GrowingImage} alt="library" className={classes.media} />
      <Typography variant="subtitle1" component="p" align="center" gutterBottom>
        Aleph Beta videos are for every type of teacher, leader, and learner who seek to strengthen
        their love of Torah. As an Aleph Beta Educator you’ll have an entire library of Torah videos
        at your fingertips. Teachers from all over the world use our videos to provide meaningful
        insight for their students within each video, as Rabbi Fohrman seeks to uncover the beauty
        behind the Torah. The courses are designed to fit seamlessly into your current curriculum
        and empower teachers and students to claim ownership of the text thorugh active learning and
        passionate debate with the Torah’s great questions. Each videos wrestles with profound and
        plaguing questions, with which every student struggles: “Does God hear my prayers?, Is there
        a God?, How does God speak to me today?”
      </Typography>
      <Typography variant="subtitle1" component="p" align="center" gutterBottom>
        An Aleph Beta classroom never stops growing - partner with us in creating a revolution in
        learning.
      </Typography>
      <Grid container direction="row" justify="center" alignItems="stretch">
        {LEARNING_METHODS &&
          LEARNING_METHODS.map(method => (
            <Box
              key={method.title}
              component={Grid}
              item
              xs={12}
              md={4}
              bgcolor={method.backgroundColor}
              pt={3}
              px={1}
            >
              <Typography
                variant="h6"
                component="p"
                align="center"
                className={classes.textColor}
                gutterBottom
              >
                {method.title}
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                align="center"
                className={classes.textColor}
              >
                {method.text}
              </Typography>
            </Box>
          ))}
      </Grid>
    </Box>
  );
};

ClassroomTab.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default ClassroomTab;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PlayCircleOutlined from '@material-ui/icons/PlayCircleOutlineRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import MediaPlayer from '~components/media-player';
import EducationSigUpButton from '~components/education/EducationSigUpButton';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    maxHeight: '100%'
  },
  iconStyle: {
    fontSize: '120px',
    color: '#fff',
    cursor: 'pointer'
  },
  buttonStyle: {
    background: '#f0be6d',
    position: 'absolute',
    bottom: '-20px',
    zIndex: '1'
  },
  textColor: {
    color: '#fff'
  }
}));

const EducationHero = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Typography variant="h2" align="center" className={classes.textColor} gutterBottom>
        Aleph Beta Education
      </Typography>

      <PlayCircleOutlined className={classes.iconStyle} onClick={handleClickOpen} />

      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}
      >
        <DialogContent>
          <MediaPlayer url="https://clevertech.wistia.com/medias/qntage1c6s" playing />
        </DialogContent>
      </Dialog>
      <EducationSigUpButton className={classes.buttonStyle}>Sign Up Now!</EducationSigUpButton>
    </>
  );
};

export default EducationHero;

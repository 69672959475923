import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import FirstPartner from '~images/education/partners/partner-1.png';
import SecondPartner from '~images/education/partners/partner-2.png';
import ThirdPartner from '~images/education/partners/partner-3.png';
import FourthPartner from '~images/education/partners/partner-4.png';
import FifthPartner from '~images/education/partners/partner-5.png';

const PARTNERS_IMAGES = [FirstPartner, SecondPartner, ThirdPartner, FourthPartner, FifthPartner];

const EducationPartners = () => (
  <>
    <Typography variant="h4" component="h5" align="center" gutterBottom>
      Just Some of Our Day School Partners
    </Typography>
    <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
      {PARTNERS_IMAGES &&
        PARTNERS_IMAGES.map((partner, i) => (
          <Grid key={partner} item xs={12} md={3}>
            <img src={partner} alt={`alephbeta partner ${i + 1}`} />
          </Grid>
        ))}
    </Grid>
  </>
);

export default EducationPartners;

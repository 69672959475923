import React from 'react';

import ContentZoom from 'react-content-zoom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import MediaPlayer from '~components/media-player/index';
import Zoom3 from '~images/education/zoom3.jpg';
import Zoom4 from '~images/education/zoom4.jpg';

const useStyles = makeStyles(() => ({
  zoom: {
    '& div>.zoomed-image': {
      backgroundSize: 'cover',
      cursor: 'pointer',
      margin: '0 auto'
    }
  }
}));

const EducationMaterials = () => {
  const classes = useStyles();

  return (
    <Box py={5}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={5}
        className={classes.zoom}
      >
        <Grid item xs={12} md={8}>
          <Typography variant="h4" component="h3" align="center">
            Educational Materials
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <MediaPlayer url="https://clevertech.wistia.com/medias/uzl1aq9suo" playing={false} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <ContentZoom
            zoomPercent={300}
            largeImageUrl={Zoom3}
            imageUrl={Zoom3}
            contentHeight={350}
            contentWidth={270}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <ContentZoom
            zoomPercent={300}
            largeImageUrl={Zoom4}
            imageUrl={Zoom4}
            contentHeight={350}
            contentWidth={270}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EducationMaterials;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import EducationSigUpButton from '~components/education/EducationSigUpButton';

const useStyles = makeStyles(theme => ({
  linkStyle: {
    marginTop: theme.spacing(3)
  },
  buttonStyle: {
    background: '#f0be6d'
  }
}));

const EducationPricing = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" component="h3" gutterBottom>
        Pricing
      </Typography>
      <EducationSigUpButton className={classes.buttonStyle}>Sign Up Now!</EducationSigUpButton>
    </>
  );
};

export default EducationPricing;

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ClassroomImage from '~images/education/classroom-tab-image.jpg';

const LIST_ITEMS = [
  'Unlimited Viewing Time in our Library',
  'Printable Lecture Outlines',
  'Written Transcripts for Each Video',
  'Teacher Guides Built for Seamless Integration into Your Classroom',
  'Access to Exclusive Courses',
  'Early Access to our Pre-release Courses (Holidays and New Series Material)',
  'Full Access to Our Mobile App for Apple & Android',
  'Exclusive Access to our Live Stream Webinars with Rabbi Fohrman',
  'Ability to Create Sub Accounts for Students to Use at Home!'
];

const useStyles = makeStyles(() => ({
  media: {
    display: 'flex',
    maxWidth: '100%'
  },
  underlineText: {
    borderBottom: '2px solid #dee1e2',
    display: 'inline-block'
  },
  listItemStyle: {
    color: '#727b80'
  }
}));

const BenefitsTab = ({ value, index }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box hidden={value !== index} p={isSmallerThanSm ? 0 : 3}>
      <img src={ClassroomImage} alt="library" className={classes.media} />
      <Typography variant="subtitle1" component="p">
        Each Educator account will receive access to our entire library of unbelievable Torah
        resources. You’ll also receive:
      </Typography>
      <Box py={1}>
        <ul>
          {LIST_ITEMS.map(listItem => (
            <li key={listItem}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.listItemStyle}
                gutterBottom
              >
                {listItem}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Typography variant="subtitle1" component="p" className={classes.underlineText}>
        And remember - all donations are tax-deductible!
      </Typography>
    </Box>
  );
};
BenefitsTab.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired
};

export default BenefitsTab;

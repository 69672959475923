import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Container from '~components/Container';
import Link from '~components/Link';

import ContactBg from '~images/education/contact-bg.jpg';

const useStyles = makeStyles(() => ({
  textColor: {
    color: '#fff'
  }
}));

const EducationContacts = () => {
  const classes = useStyles();

  return (
    <>
      <Container center background={`url(${ContactBg})`}>
        <Box py={8}>
          <Typography
            variant="h4"
            component="h5"
            align="center"
            className={classes.textColor}
            gutterBottom
          >
            Have questions?
          </Typography>
        </Box>
      </Container>
      <Box py={8}>
        <Typography variant="h6" component="h5" align="center">
          <Link to="mailto:info@alephbeta.org">Email: info@alephbeta.org</Link>
          <br />
          This is our preferred and fastest way of communication We usually respond in less than 1
          business day!
        </Typography>
      </Box>
    </>
  );
};

export default EducationContacts;

import PropTypes from 'prop-types';
import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import Slider from 'react-slick';

const useStyles = makeStyles(theme => ({
  carouselBtn: {
    position: 'absolute',
    bottom: '0%',
    right: '50%',
    zIndex: 1,
    [theme.breakpoints.up('xs')]: {
      left: props => (props.isNext ? null : '15%'),
      right: props => (props.isNext ? '20%' : null)
    },
    [theme.breakpoints.up('md')]: {
      left: props => (props.isNext ? null : '40%'),
      right: props => (props.isNext ? '40%' : null)
    },
    [theme.breakpoints.up('lg')]: {
      left: props => (props.isNext ? null : '44%'),
      right: props => (props.isNext ? '44%' : null)
    }
  },
  textPadding: {
    paddingBottom: theme.spacing(3)
  }
}));

const TESTIMONIALS = [
  {
    text: "As a teacher I enjoy R' Fohrman's twist on the stories. Most of these stories we have leaned since we we little, and we have been teaching the same way. as a student and as a teacher I always learn something new. The parsha videos are great for me to always have something new to teach my students and my adult parsha classes. With the new age of teaching, going electronic, the videos are an easy way to incorporate torah technology into the classroom. Students can learn a video in school and then have the opportunity to watch it at home and show their parents too. I have had many parents tell me that they really enjoyed learning the videos with their kids. For new teachers, the videos are a crucial tool for being able to be a great teacher. I can only imagine how much smother my classes would have run if I would have had the videos 6 years ago when I began teaching. I have often shared videos with other teachers at Akiva and made suggestions for teachers whom I think would enjoy certain videos. Whenever I'm stuck and need a great lesson plan, I know I always have Rabbi Fohrman's help.",
    person: 'Ariella Skoczylas, Akiva Day School in Detroit - Teacher'
  },
  {
    text: 'I really enjoyed learning from the Aleph Beta videos. They were very interesting and I felt that I learned things that are not typically learned in a regular classroom setting. I really enjoyed learning Tanach in a way that is different from what I am used to. Additionally, they were quite complex so I really enjoyed delving into the concepts and challenging myself.',
    person: 'Yardena S., Akiva Day School in Detroit, Senior - Student'
  },
  {
    text: 'I personally loved Aleph Beta. I loved the way the videos were done, the relation to real life scenarios, and the examples... cliffhangers and all. The answers were completely mind­blowing, and backed by excellent details and research that not many people could have even noticed! Even I can barely fathom the amount of time it must have taken to create these videos. I love Aleph Beta and hope to use it again.',
    person: 'Dylan S, Moriah Day School, 6th Grade - Student'
  },
  {
    text: "Rabbi Fohrman's Pesach course challenged my students to think about the holiday in an entirely new light. It both broadened and deepened their perspective not only on the holiday itself, but also on their role and responsibility as Jews.",
    person: 'Hani Loweinstein, Teacher at Shalhevet LI - Teacher'
  },
  {
    text: 'I really enjoyed the video’s. I learned so much more things about this book. The video’s taught me many different ways to look at the story’s deeper meaning. I really enjoyed how the video’s compared some of the challenging subjects to real life situations.',
    person: 'Sam H., Moriah Day School, 6th Grade - Student'
  },
  {
    text: 'It was absolutely fabulous! Thank you for creating such a wonderful way to learn these important ideas. The videos are interesting, informative and very well done. I have since used a few of them in my chumash class( high school) and it went over so well that the girls wanted me to bring them in more often. I have shared this gold mine with many of our teachers and it has generated a lot of excitement. Thank you again. Yasher koach.',
    person: 'Mrs. Adina Ribacoff, Principal of Tiferes Bais Yaakov Toronto - Teacher'
  },
  {
    text: 'I thought that the Aleph Beta program was outstandingly amazingly awesome. It was educational, important, interesting, and fun. The program taught me a lot. Another thing that taught me a lot was hearing my classmate’s feedback. My classmates have such smart ideas all thanks to the Aleph Beta programing. The information was so important to know and there was no unnecessary information. My favorite part of Aleph Beta was the music and suspense. The music made it so much more interesting. I know this sounds bad, but every five minutes I turned to my neighbor and told her, “Oh my gosh this music is so good!” I’m not lying I actually said that. I liked how it was split into six different videos. It made me thirsty for more. The entire week I was waiting for Rabbi Fein’s class, so that we could watch the Aleph Beta videos. It was also a chill way of learning. I would do that everyday. I actually learned and I usually don’t learn much from videos. I usually watch them and know the information and then forget it. I remember the entire Yonah story thanks to Aleph Beta.',
    person: 'Gabrielle G, - Student'
  },
  {
    text: 'Mamash beautiful Torah!! We enjoy every week,but this was one was one of the best.Love the synthesis of amazing textual analyzation coupled with a very meaningful message. Thank you so much.',
    person: 'DRS Class 12 C - Student'
  },
  {
    text: "Personally, I am delighted to have such well-made and interesting videos to show. We are a small school, and I don't want my voice and thoughts to be the only ones my students hear. Rabbi Fohrman always has interesting things to say and the visuals support the explanations beautifully. The focus on grammar and vocabulary is done remarkably well for a presentation done with translation, and is great for my students, whose Hebrew is often weak but whom I am trying to teach to focus on the Hebrew words.",
    person: 'Deborah Klapper, The BInah School in Massachusetts - Teacher'
  },
  {
    text: 'TABC Y7 Chumash is so appreciative of all of these videos. We watch them every week, and the students bring the divrei torah home to their Shabbat Tables. Yashar Koach and please keep up your amazing work. Thank you!',
    person: 'TABC 7th Grade - Student'
  },
  {
    text: 'Our class had some wonderful thoughts regarding the underlining connection between the metzora, the story of pesach in general and the korban pesach: 1- in both cases the metzora and the nation had reached the lowest level of tuma- this korban allows the individual and the nation to raise their level of kedusha 2- the metzora chooses with his lashon hara to separate himself from the rest of the nation; the korban pesach is the mirror image it is the way in which the individual becomes a part of the community 3-in both cases we acknowledge the power of Hashem and His control over our lives- in afflicting us with a strange skin disease and with the plagues 4- both cases allow us to crawl back from the "dead" and the bad things they have been a part of our lives - the metzora from his isolation and the nation from their years of servitude',
    person: 'SAR Academy 7th Grade - Student'
  }
];

const CarouselArrow = ({ onClick, isNext }) => {
  const classes = useStyles({ isNext });
  return (
    <Button variant="outlined" onClick={onClick} size="small" className={classes.carouselBtn}>
      {isNext ? <NavigateNext /> : <NavigateBefore />}
    </Button>
  );
};

CarouselArrow.propTypes = {
  onClick: PropTypes.func,
  isNext: PropTypes.bool
};

CarouselArrow.defaultProps = {
  onClick: () => {},
  isNext: false
};

const EducationTestimonials = () => {
  const classes = useStyles();

  const carouselSettings = {
    className: 'carousel',
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    prevArrow: <CarouselArrow />,
    nextArrow: <CarouselArrow isNext />
  };
  return (
    <>
      <Typography variant="h4" component="h5" align="center" gutterBottom>
        Testimonials
      </Typography>
      <Slider {...carouselSettings}>
        {TESTIMONIALS &&
          TESTIMONIALS.map(testimonial => (
            <Box py={6} px={1} key={testimonial.person}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.textPadding}
                align="center"
                gutterBottom
              >
                {testimonial.text}
              </Typography>
              <Typography variant="subtitle1" component="p" align="center">
                {testimonial.person}
              </Typography>
            </Box>
          ))}
      </Slider>
      <style>{`
  .carousel {
    width: 100%;
  }
`}</style>
    </>
  );
};

export default EducationTestimonials;
